.form-msg-error {
  display: flex;
  color: #FFFFFF;
  font-size: 14px;
  padding: 8px 16px;
  border-radius: 5px;
  align-items: center;
  background-color: #EF404B;
  img {
    margin-right: 16px;
    filter: brightness(0) invert(1);
  }
}

.form-error-text {
  font-size: 12px;
  padding: 8px 0px;
  align-items: center;
  color: #EF404B;
}

.bpp-form-error  {
  display: flex;
  color: #FFFFFF;
  font-size: 14px;
  padding: 8px 16px;
  border-radius: 5px;
  align-items: center;
  background-color: #EF404B;
  img {
    margin-right: 16px;
    filter: brightness(0) invert(1);
  }
}

.bpp-label {
  color: #525252;
}

.bpp-select__menu, 
.bpp-select__control, 
.bpp-select__placeholder {
  border-radius: 0px !important;
  &.css-yk16xz-control {
    color: #b2b2b2;
    border-radius: 0px !important;
    background-color: #f4f4f4 !important;
  }
}

.bpp-select-error__menu, 
.bpp-select-error__control, 
.bpp-select-error__placeholder {
  border-color: #DA1D28 !important;
  border-radius: 0px !important;
  &.css-yk16xz-control {
    color: #b2b2b2;
    border-radius: 0px !important;
    background-color: #f4f4f4 !important;
  }
}