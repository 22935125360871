#CreateCourse.bx--content {
  background: #FFFFFF;
}

.bpp-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bx--form {
  padding-left: 10vw;
  padding-right: 10vw;
  align-items: center;
}

.bpp-register-title {
  width: 80%;
  padding-left: 10vw;
}

.bpp-register-divisor {
  width: 100%;
  border-bottom: 1px solid green;
  border-spacing: 15px;
}