#AdminLogin.bx--content {
  background: #FFFFFF;
  padding: 0 !important;
  height: 100%;
}
.bpp-login{
  .bpp-login-cont {
    width: 100%;
    height: 100%;
    justify-content: space-around;
  }
  
  .bx--form {
    padding-left: 10vw;
    padding-right: 10vw;
    align-items: center;
  }
  
  .bpp-login-title {
    width: 80%;
    padding-left: 10vw;
  }
  
  .bpp-login-divisor {
    width: 100%;
    border-bottom: 1px solid green;
    border-spacing: 15px;
  }
  
  .bpp-login-img-cap{
    width: 60%;
    margin-top: 30%;
    margin-left: 46%;
  }
  .bpp-login-img-per{
    width: 85%;
  }
  .bpp-register-button{
    text-align: center;
    text-decoration: none;
  }
  .bpp-register-label{
    font-size: 14px;
  }
  .shy-row-separator{
    width: 100% !important;
  }

  @media (max-width: 1189px){
    .bpp-login-img-per{
      width: 100%;
      height: 100%;
    }
  }
  @media (max-width: 1055px){
    .bpp-login-img-cap{
      width: 50%;
      margin-top: 20%;
      margin-left: 28%;
    }
  }
}