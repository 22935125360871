.container{
  align-items: center;
}

#empty {
  h1 {
    font-size: 1.3rem !important;
  }
  
  .not-empty{
    width: 35%;
  }
}