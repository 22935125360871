#Register.bx--content {
  background: #FFFFFF;
  padding: 0 !important;
  height: 100%;
}
.shy-register{
  .bpp-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .bx--form {
    padding-left: 10vw;
    padding-right: 10vw;
    align-items: center;
  }
  
  .bpp-register-title {
    width: 80%;
    padding-left: 10vw;
  }
  
  .bpp-register-divisor {
    width: 100%;
    border-bottom: 1px solid green;
    border-spacing: 15px;
  }
  
  .bpp-register-img{
    width: inherit;
  }
  
  .bpp-register-label{
    font-size: 14px;
  }
  .shy-row-separator{
    width: 100% !important;
  }
}