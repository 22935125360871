@import './form.scss'; 

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
}

.shy-row-separator {
  width: 65vw;
  height: 100%;
  padding: 18px 16px;
  box-sizing: border-box;
  border-bottom: 1px solid #E6E6E6;
  span {
    width: 1px;
    height: 30px;
    display: flex;
    min-width: 1px;
    min-height: 30px;
    background-color: #E6E6E6;
  }
  &.shy-header-separator {
    padding: 6px 16px;
    background-color: #fafafa;
    span {
      height: 20px;
      min-height: 20px;
      background-color: #E6E6E6;
    }
  }
}

.bpp-full-size {
  width: 100%;
  height: 100%
}
.bpp-full-height { height: 100%; }
.bpp-full-width { width: 100%; }

.bpp-w-5 { width: 5%; }
.bpp-w-10 { width: 10%; }
.bpp-w-20 { width: 20%; }
.bpp-w-25 { width: 25%; }
.bpp-w-30 { width: 30%; }
.bpp-w-40 { width: 40%; }
.bpp-w-50 { width: 50%; }
.bpp-w-60 { width: 60%; }
.bpp-w-70 { width: 70%; }
.bpp-w-80 { width: 80%; }
.bpp-w-90 { width: 90%; }
.bpp-w-100 { width: 100%; }

.bpp-h-5 { height: 5%; }
.bpp-h-10 { height: 10%; }
.bpp-h-20 { height: 20%; }
.bpp-h-25 { height: 25%; }
.bpp-h-30 { height: 30%; }
.bpp-h-40 { height: 40%; }
.bpp-h-50 { height: 50%; }
.bpp-h-60 { height: 60%; }
.bpp-h-70 { height: 70%; }
.bpp-h-80 { height: 80%; }
.bpp-h-90 { height: 90%; }
.bpp-h-100 { height: 100%; }

.bpp-w-120 {
  width: 120px;
  max-width: 120px;
}
.bpp-w-180 {
  width: 180px;
  max-width: 180px;
}
.bpp-w-300 {
  width: 300px;
  max-width: 300px;
}

/*********************************************/
/** MARGINS **********************************/
/*********************************************/

.bpp-m-16 {
  margin: 16px;
}

.bpp-mv-64 {
  margin-top: 64px;
  margin-bottom: 64px;
}
.bpp-mv-32 {
  margin-top: 32px;
  margin-bottom: 32px;
}
.bpp-mv-16 {
  margin-top: 16px;
  margin-bottom: 16px;
}
.bpp-mv-8 {
  margin-top: 8px;
  margin-bottom: 8px;
}
.bpp-mv-6 {
  margin-top: 6px;
  margin-bottom: 6px;
}
.bpp-mv-4 {
  margin-top: 4px;
  margin-bottom: 4px;
}
.bpp-mv-2 {
  margin-top: 2px;
  margin-bottom: 2px;
}

.bpp-mh-64 {
  margin-left: 64px;
  margin-right: 64px;
}
.bpp-mh-32 {
  margin-left: 32px;
  margin-right: 32px;
}
.bpp-mh-16 {
  margin-left: 16px;
  margin-right: 16px;
}
.bpp-mh-8 {
  margin-left: 8px;
  margin-right: 8px;
}
.bpp-mh-6 {
  margin-left: 6px;
  margin-right: 6px;
}
.bpp-mh-4 {
  margin-left: 4px;
  margin-right: 4px;
}
.bpp-mh-2 {
  margin-left: 2px;
  margin-right: 2px;
}

.bpp-ml-64 { margin-left: 64px }
.bpp-ml-32 { margin-left: 32px }
.bpp-ml-16 { margin-left: 16px }
.bpp-ml-8 { margin-left: 8px }
.bpp-ml-6 { margin-left: 6px }
.bpp-ml-4 { margin-left: 4px }
.bpp-ml-2 { margin-left: 2px }

.bpp-mr-64 { margin-right: 64px }
.bpp-mr-32 { margin-right: 32px }
.bpp-mr-16 { margin-right: 16px }
.bpp-mr-8 { margin-right: 8px }
.bpp-mr-6 { margin-right: 6px }
.bpp-mr-4 { margin-right: 4px }
.bpp-mr-2 { margin-right: 2px }

.bpp-mt-64 { margin-top: 64px }
.bpp-mt-32 { margin-top: 32px }
.bpp-mt-16 { margin-top: 16px }
.bpp-mt-8 { margin-top: 8px }
.bpp-mt-6 { margin-top: 6px }
.bpp-mt-4 { margin-top: 4px }
.bpp-mt-2 { margin-top: 2px }

.bpp-mb-64 { margin-bottom: 64px }
.bpp-mb-32 { margin-bottom: 32px }
.bpp-mb-16 { margin-bottom: 16px }
.bpp-mb-8 { margin-bottom: 8px }
.bpp-mb-6 { margin-bottom: 6px }
.bpp-mb-4 { margin-bottom: 4px }
.bpp-mb-2 { margin-bottom: 2px }


/*********************************************/
/** PADDINGS *********************************/
/*********************************************/

.bpp-p-16 {
  padding: 16px;
}

.bpp-pv-64 {
  padding-top: 64px;
  padding-bottom: 64px;
}
.bpp-pv-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}
.bpp-pv-32 {
  padding-top: 32px;
  padding-bottom: 32px;
}
.bpp-pv-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}
.bpp-pv-8 {
  padding-top: 8px;
  padding-bottom: 8px;
}
.bpp-pv-6 {
  padding-top: 6px;
  padding-bottom: 6px;
}
.bpp-pv-4 {
  padding-top: 4px;
  padding-bottom: 4px;
}
.bpp-pv-2 {
  padding-top: 2px;
  padding-bottom: 2px;
}

.bpp-ph-64 {
  padding-left: 64px;
  padding-right: 64px;
}
.bpp-ph-40 {
  padding-left: 40px;
  padding-right: 40px;
}
.bpp-ph-32 {
  padding-left: 32px;
  padding-right: 32px;
}
.bpp-ph-16 {
  padding-left: 16px;
  padding-right: 16px;
}
.bpp-ph-8 {
  padding-left: 8px;
  padding-right: 8px;
}
.bpp-ph-6 {
  padding-left: 6px;
  padding-right: 6px;
}
.bpp-ph-4 {
  padding-left: 4px;
  padding-right: 4px;
}
.bpp-ph-2 {
  padding-left: 2px;
  padding-right: 2px;
}

.bpp-pl-64 { padding-left: 64px }
.bpp-pl-40 { padding-left: 40px }
.bpp-pl-32 { padding-left: 32px }
.bpp-pl-16 { padding-left: 16px }
.bpp-pl-8 { padding-left: 8px }
.bpp-pl-6 { padding-left: 6px }
.bpp-pl-4 { padding-left: 4px }
.bpp-pl-2 { padding-left: 2px }

.bpp-pr-64 { padding-right: 64px }
.bpp-pr-40 { padding-right: 40px }
.bpp-pr-32 { padding-right: 32px }
.bpp-pr-16 { padding-right: 16px }
.bpp-pr-8 { padding-right: 8px }
.bpp-pr-6 { padding-right: 6px }
.bpp-pr-4 { padding-right: 4px }
.bpp-pr-2 { padding-right: 2px }

.bpp-pt-64 { padding-top: 64px }
.bpp-pt-40 { padding-top: 40px }
.bpp-pt-32 { padding-top: 32px }
.bpp-pt-16 { padding-top: 16px }
.bpp-pt-8 { padding-top: 8px }
.bpp-pt-6 { padding-top: 6px }
.bpp-pt-4 { padding-top: 4px }
.bpp-pt-2 { padding-top: 2px }

.bpp-pb-64 { padding-bottom: 64px }
.bpp-pb-40 { padding-bottom: 40px }
.bpp-pb-32 { padding-bottom: 32px }
.bpp-pb-16 { padding-bottom: 16px }
.bpp-pb-8 { padding-bottom: 8px }
.bpp-pb-6 { padding-bottom: 6px }
.bpp-pb-4 { padding-bottom: 4px }
.bpp-pb-2 { padding-bottom: 2px }


/*********************************************/
/** FLEX *************************************/
/*********************************************/

.bpp-flx { display: flex; }
.bpp-flx-end {
  display: flex;
  justify-content: flex-end;
}
.bpp-flx-btwn {
  display: flex;
  justify-content: space-between;
}
.bpp-flx-clmn {
  display: flex;
  flex-direction: column;
}
.bpp-flx-c {
  display: flex;
  align-items: center;
  justify-content: center;
}
.bpp-flx-vc {
  display: flex;
  align-items: center;
}
.bpp-flx-hc {
  display: flex;
  justify-content: center;
}

/*********************************************/
/** TEXTS ************************************/
/*********************************************/

.bpp-txt-32 {
  color: #757575;
  font-size: 32px;
  line-height: 32px;
}
.bpp-txt-24 {
  color: #757575;
  font-size: 24px;
  line-height: 24px;
}
.bpp-txt-16 {
  color: #757575;
  font-size: 16px;
  line-height: 16px;
}
.bpp-txt-14 {
  color: #757575;
  font-size: 14px;
  line-height: 16px;
}
.bpp-txt-12 {
  color: #757575;
  font-size: 12px;
  line-height: 14px;
}
.bpp-txt-10 {
  color: #757575;
  font-size: 10px;
  line-height: 12px;
}

.bpp-txt-32-w {
  color: #FFFFFF;
  font-size: 32px;
  line-height: 32px;
}
.bpp-txt-24-w {
  color: #FFFFFF;
  font-size: 24px;
  line-height: 24px;
}
.bpp-txt-16-w {
  color: #FFFFFF;
  font-size: 16px;
  line-height: 16px;
}
.bpp-txt-14-w {
  color: #FFFFFF;
  font-size: 14px;
  line-height: 16px;
}
.bpp-txt-12-w {
  color: #FFFFFF;
  font-size: 12px;
  line-height: 14px;
}
.bpp-txt-10-w {
  color: #FFFFFF;
  font-size: 10px;
  line-height: 12px;
}

.bpp-txt-32-r {
  color: #EF404B;
  font-size: 32px;
  line-height: 32px;
}
.bpp-txt-24-r {
  color: #EF404B;
  font-size: 24px;
  line-height: 24px;
}
.bpp-txt-16-r {
  color: #EF404B;
  font-size: 16px;
  line-height: 16px;
}
.bpp-txt-14-r {
  color: #EF404B;
  font-size: 14px;
  line-height: 16px;
}
.bpp-txt-12-r {
  color: #EF404B;
  font-size: 12px;
  line-height: 14px;
}
.bpp-txt-10-r {
  color: #EF404B;
  font-size: 10px;
  line-height: 12px;
}

.bpp-txt-32-pg {
  color: #9E9E9E;
  font-size: 32px;
  line-height: 32px;
}
.bpp-txt-24-pg {
  color: #9E9E9E;
  font-size: 24px;
  line-height: 24px;
}
.bpp-txt-16-pg {
  color: #9E9E9E;
  font-size: 16px;
  line-height: 16px;
}
.bpp-txt-14-pg {
  color: #9E9E9E;
  font-size: 14px;
  line-height: 16px;
}
.bpp-txt-12-pg {
  color: #9E9E9E;
  font-size: 12px;
  line-height: 14px;
}
.bpp-txt-10-pg {
  color: #9E9E9E;
  font-size: 10px;
  line-height: 12px;
}

.bpp-txt-32-g {
  color: #12CE66;
  font-size: 32px;
  line-height: 32px;
}
.bpp-txt-24-g {
  color: #12CE66;
  font-size: 24px;
  line-height: 24px;
}
.bpp-txt-16-g {
  color: #12CE66;
  font-size: 16px;
  line-height: 16px;
}
.bpp-txt-14-g {
  color: #12CE66;
  font-size: 14px;
  line-height: 16px;
}
.bpp-txt-12-g {
  color: #12CE66;
  font-size: 12px;
  line-height: 14px;
}
.bpp-txt-10-g {
  color: #12CE66;
  font-size: 10px;
  line-height: 12px;
}

.bpp-txt-14-o {
  color: #FF5722;
  font-size: 14px;
}
.bpp-txt-14-g {
  color: #12CE66;
  font-size: 14px;
}

/*********************************************/
/** TAGS *************************************/
/*********************************************/

.bpp-tag,
.bpp-tag-w {
  font-size: 10px;
  padding: 2px 4px;
  max-width: 250px;
  border-radius: 5px;
  width: fit-content;
  align-items: center;
  white-space: nowrap;
  display: inline-flex;
  min-width: fit-content;
  text-overflow: ellipsis;
  background-color: white;
}
.bpp-tag {
  color: #FFFFFF;
  background-color: #757575;
}
.bpp-tag-w {
  color: #757575;
  background-color: #FFFFFF;
}

/*********************************************/
/** SHADOWS **********************************/
/*********************************************/

.bpp-shadow-1,
.bpp-shadow-h1 {
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24) !important;
}
.bpp-shadow-h1 {
  will-change: box-shadow;
  transition: box-shadow 0.3s ease;
}
.bpp-shadow-h1:hover {
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23) !important;
}

.bpp-shadow-2,
.bpp-shadow-h2 {
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23) !important;
}
.bpp-shadow-h2 {
  will-change: box-shadow;
  transition: box-shadow 0.3s ease;
}
.bpp-shadow-h2:hover {
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23) !important;
}

.bpp-shadow-3,
.bpp-shadow-h3 {
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23) !important;
}
.bpp-shadow-h3 {
  will-change: box-shadow;
  transition: box-shadow 0.3s ease;
}
.bpp-shadow-h3:hover {
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22) !important;
}

.bpp-shadow-4,
.bpp-shadow-h4 {
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22) !important;
}
.bpp-shadow-h4 {
  will-change: box-shadow;
  transition: box-shadow 0.3s ease;
}
.bpp-shadow-h4:hover {
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22) !important;
}

/*********************************************/
/** UTILS ************************************/
/*********************************************/

.bpp-txt-clickable {
  color: #1B214C;
  text-decoration: underline;
}

.bpp-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bpp-spin {
  animation: spin 0.75s linear infinite;
}

.bpp-pointer,
.bpp-pointer-h {
  cursor: pointer;
}
.bpp-pointer-h:hover {
  cursor: pointer;
  animation: shy-pulse 1s infinite;
}

.bpp-opaque {
  opacity: 0.25;
  cursor: default;
  pointer-events: none;
}

.bpp-invert {
  filter: brightness(0) invert(1);
}

.bpp-bold {
  font-weight: bold;
}

.bpp-z-idx-1 { z-index: 1 !important }
.bpp-z-idx-2 { z-index: 2 !important }
.bpp-z-idx-3 { z-index: 3 !important }
.bpp-z-idx-4 { z-index: 4 !important }
.bpp-z-idx-5 { z-index: 5 !important }

.bpp-txt-center {
  text-align: center;
}

.bpp-job {
  padding: 16px 0;
  border-top: 1px solid #e6e6e6;
  &:not(last-of-type) {
    border-bottom: 1px solid #e6e6e6;
  }
}

.bpp-overlay {
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: none;
  background-color: rgba(255, 255, 255, 0.8);
}

.bpp-rel {
  position: relative;
}

.bpp-brk {
  word-break: break-all;
}

.bpp-disabled {
  opacity: 0.8;
  cursor: not-allowed;
  pointer-events: none;
}

.img-gray,
.bpp-gray {
  filter: invert(52%) sepia(18%) saturate(0%) hue-rotate(225deg) brightness(86%) contrast(111%);
}
.img-yellow,
.bpp-yellow {
  filter: invert(80%) sepia(82%) saturate(1659%) hue-rotate(332deg) brightness(103%) contrast(101%);
}
.img-dark-yellow,
.bpp-dark-yellow {
  filter: invert(62%) sepia(36%) saturate(4035%) hue-rotate(15deg) brightness(96%) contrast(101%);
}
.img-navy,
.bpp-navy {
  filter: invert(16%) sepia(44%) saturate(2095%) hue-rotate(212deg) brightness(85%) contrast(91%);
}
.img-light-navy,
.bpp-light-navy {
  filter: invert(50%) sepia(13%) saturate(806%) hue-rotate(179deg) brightness(93%) contrast(84%);
}
.img-blue,
.bpp-blue {
  filter: invert(53%) sepia(76%) saturate(1015%) hue-rotate(170deg) brightness(100%) contrast(103%);
}
.img-green,
.bpp-green {
  filter: invert(73%) sepia(49%) saturate(4860%) hue-rotate(100deg) brightness(99%) contrast(86%);
}
.img-orange,
.bpp-orange {
  filter: invert(45%) sepia(46%) saturate(4485%) hue-rotate(347deg) brightness(103%) contrast(101%);
}
.img-soft-red,
.bpp-soft-red {
  filter: invert(40%) sepia(67%) saturate(1416%) hue-rotate(325deg) brightness(90%) contrast(102%);
}
.img-red,
.bpp-red {
  filter: invert(51%) sepia(84%) saturate(5210%) hue-rotate(333deg) brightness(95%) contrast(96%);
}
.img-dark-red,
.bpp-dark-red {
  filter: invert(13%) sepia(39%) saturate(7465%) hue-rotate(346deg) brightness(92%) contrast(103%);
}
.img-wine,
.bpp-wine {
  filter: invert(8%) sepia(71%) saturate(6062%) hue-rotate(350deg) brightness(81%) contrast(98%);
}
.img-white,
.bpp-white {
  filter: brightness(0) invert(1);
}
.img-black,
.bpp-black {
  filter: invert(11%) sepia(9%) saturate(355%) hue-rotate(314deg) brightness(92%) contrast(95%);
}

.bpp-gone {
  display: none;
}

@media (max-width: 850px) {
  .bpp-mt-8-mbl {
    margin-top: 8px;
  }
  .bpp-mt-16-mbl {
    margin-top: 16px;
  }
  .bpp-flx-clmn-mbl {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 650px) {
  .bpp-gone-mbl {
    display: none;
  }
}


::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #757575;
}
