.not-found{
  width: 25%;
}
#Courses {
  height: 100%;
  &.bx--content {
    padding-bottom: 0px;
  }
  .bpp-teacher-body{
    overflow: auto;
    height: calc(100vh - 145px);
    padding-bottom: 32px;
  }
}
.bpp-pagination{
  position: fixed;
  bottom: 0;
  width: calc(100% - 65px);
}