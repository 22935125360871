.content{
    margin-top: 8px;
}
.buttonSet{
    margin-top: 32px;
}
.landing-title{
    color: #64ad53;
    text-align: center;
}
.landing-subtitle{
    text-align: center;
}
.content{
    text-align: center;
}
.bx--btn-set{
    justify-content: center;
}