.bpp-overview-container {
    overflow: scroll;
    height: calc(100% - 38px);
}
.bpp-overview-picto{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}
.bpp-overview-number {
    font-weight: bold;
    font-size: 32px;
}
.bpp-overview-item{
    margin-left: 32px;
    width: 25%;
}
.bpp-overview-item:first-child{
    margin-left: 0px;
}
.bpp-class-detail-context{
    width: 50%;
}
.bpp-class-resume, .bpp-class-overview {
    height: calc(100% - 16px);
}