.bpp-class-achivements {
    margin: 32px 0px 0px 0px;
    width: 100%
}

.bpp-class-achivements ul {
    width: 100%;
}

.bpp-class-achivements li.bpp-flx-c {
    justify-content: space-around;
}

.bpp-achv-students{
    width: 100%
}

.progress-bar {
    width: 15vw !important;
}

.bpp-text-detail {
    width: 15vw !important;
}
